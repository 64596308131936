import { Box, ResponsiveContext, Text } from 'grommet'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { MarketplaceCategory, UserMarketplaceCategoryOptions } from 'lib/constants'

import { MarketplaceCategorySelect } from './MarketplaceCategorySelect'

interface MarketplaceFiltersContentProps {
  sortedMarketplaceCategories: MarketplaceCategory[]
  selectedCategoryOptions: UserMarketplaceCategoryOptions | undefined
  setSelectedCategoryOptions: React.Dispatch<
    React.SetStateAction<UserMarketplaceCategoryOptions | undefined>
  >
  loading?: boolean
  useMaxSelectedPerCategory?: boolean
  showLabels?: boolean
  translations:
    | {
        [key: string]: string
      }
    | undefined
}

export function MarketplaceFiltersContent({
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  loading,
  useMaxSelectedPerCategory = false,
  showLabels = false,
  translations,
  sortedMarketplaceCategories,
}: MarketplaceFiltersContentProps) {
  const { t } = useTranslation()
  const deviceSize = React.useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'

  return (
    <Box
      style={{
        borderRadius: '16px',
        display: 'grid',
        gridTemplateColumns: isMobile || showLabels ? '1fr' : 'repeat(auto-fit, min(100%, 230px))',
        width: '100%',
        rowGap: showLabels ? '16px' : '24px',
        columnGap: '16px',
        justifyContent: 'start',
        pointerEvents: loading ? 'none' : undefined,
      }}>
      {sortedMarketplaceCategories?.map((category) => (
        <Box gap="xsmall" justify="center" key={category.id}>
          {showLabels && (
            <Text size="13px">
              {`${t('marketplace.select')} ${translations?.[category.id] ?? category.display_name}${
                category.max_selected ? ` (${t('marketplace.up-to')} ${category.max_selected})` : ''
              }`}
            </Text>
          )}
          <MarketplaceCategorySelect
            loading={loading}
            showLabels={showLabels}
            marketplaceCategory={category}
            selectedCategoryOptions={selectedCategoryOptions}
            setSelectedCategoryOptions={setSelectedCategoryOptions}
            maxSelected={useMaxSelectedPerCategory ? category.max_selected : undefined}
            label={translations?.[category.id] ?? category.display_name}
            options={category.marketplace_category_options}
            translations={translations}
          />
        </Box>
      ))}
    </Box>
  )
}
