import { Box, ResponsiveContext, Text } from 'grommet'
import React, { useContext, useMemo, useState } from 'react'

import {
  MarketplaceCategory,
  Option,
  UserMarketplaceCategoryOption,
  UserMarketplaceCategoryOptions,
} from 'lib/constants'
import { handleTagSelectionChange } from 'lib/helpers'

import TickIcon from 'components/icons/common/TickIcon'
import { WhiteSearchSelect } from 'components/WhiteSearchSelect'
import { colors } from 'layout/themeColors/colors'

import { MaxSelectedCategoriesModal } from './MarketplaceProviderCard/components/MaxSelectedCategoriesModal'

type Props = {
  options?: UserMarketplaceCategoryOption[]
  selectedCategoryOptions: UserMarketplaceCategoryOptions | undefined
  setSelectedCategoryOptions: React.Dispatch<
    React.SetStateAction<UserMarketplaceCategoryOptions | undefined>
  >
  border?: string
  height?: number
  fontSize?: number
  fontWeight?: number
  label: string
  maxSelected?: number | null
  marketplaceCategory: MarketplaceCategory
  showLabels?: boolean
  loading?: boolean
  translations:
    | {
        [key: string]: string
      }
    | undefined
}

export function MarketplaceCategorySelect({
  options,
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  label,
  maxSelected,
  translations,
  marketplaceCategory,
  showLabels = false,
  loading,
}: Props) {
  const deviceSize = useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'
  const [showMaxSelectedCategoriesModal, setShowMaxSelectedCategoriesModal] = useState(false)
  if (!options) return null

  const value = useMemo(() => {
    const selectedOptions: UserMarketplaceCategoryOption[] = []
    for (const key in selectedCategoryOptions) {
      selectedCategoryOptions[key].forEach((option) => selectedOptions.push(option))
    }
    return selectedOptions
  }, [selectedCategoryOptions])

  return (
    <>
      <WhiteSearchSelect
        disablePointerEvents={loading}
        search
        filterFunc={(options: Option[], exp: RegExp) =>
          options.filter((option) =>
            exp.test(
              translations?.[(option as UserMarketplaceCategoryOption)?.id] ??
                (option as UserMarketplaceCategoryOption).display_name,
            ),
          )
        }
        multiple
        closeOnChange={false}
        dropHeight="medium"
        value={value}
        options={options}
        valueKey="id"
        background={showLabels ? colors.light1 : isMobile ? undefined : colors.light2}
        labelKey="name"
        onChange={({ option }: { option: UserMarketplaceCategoryOption }) => {
          handleTagSelectionChange(
            marketplaceCategory,
            option,
            selectedCategoryOptions,
            setSelectedCategoryOptions,
            maxSelected,
            () => setShowMaxSelectedCategoriesModal(true),
          )
        }}
        valueLabel={
          <Box
            height="40px"
            background={showLabels ? colors.light1 : isMobile ? undefined : colors.light2}
            justify="center"
            pad={{ left: '12px' }}>
            <Text
              size="16px"
              style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              {showLabels && selectedCategoryOptions?.[marketplaceCategory.id]
                ? selectedCategoryOptions?.[marketplaceCategory.id]
                    ?.map(
                      (categoryOption) =>
                        translations?.[categoryOption.id] || categoryOption.display_name,
                    )
                    ?.join(', ')
                : label}
            </Text>
          </Box>
        }>
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {(
          option: UserMarketplaceCategoryOption,
          _index,
          _options,
          { _active, _disabled, selected },
        ) => {
          return (
            <Box
              fill
              pad="12px 8px"
              justify="between"
              style={{
                backgroundColor: selected ? colors.highlighter : undefined,
              }}
              direction="row">
              <Text weight={400} size="16px">
                {translations?.[option.id] ?? option.display_name}
              </Text>
              {selected && <TickIcon color={colors.accent5} />}
            </Box>
          )
        }}
      </WhiteSearchSelect>
      {maxSelected && (
        <MaxSelectedCategoriesModal
          open={showMaxSelectedCategoriesModal}
          setOpen={setShowMaxSelectedCategoriesModal}
          onConfirm={async () => setShowMaxSelectedCategoriesModal(false)}
          maxSelected={maxSelected}
          categoryDisplayName={
            translations?.[marketplaceCategory.id] ?? marketplaceCategory.display_name
          }
        />
      )}
    </>
  )
}
