import { Select, SelectProps, ThemeContext } from 'grommet'
import React, { useState } from 'react'
import { css } from 'styled-components'

import { colors } from '../layout/themeColors/colors'
import { Option } from '../lib/constants'

import SelectDownIcon from './icons/common/SelectDownIcon'
import SelectUpIcon from './icons/common/SelectUpIcon'

type Props = {
  background?: string
  // eslint-disable-next-line @typescript-eslint/ban-types
  options: Option[]
  search?: boolean
  filterFunc?: (options: Option[], exp: RegExp) => Option[]
  border?: string
  height?: number
  fontSize?: number
  fontWeight?: number
  maxResultsInSearch?: number
  // eslint-disable-next-line @typescript-eslint/ban-types
  defaultOptionIfNoSearchResults?: string | number | boolean | JSX.Element | object
  disablePointerEvents?: boolean
} & Partial<SelectProps>
export const WhiteSearchSelect = ({
  background = colors.white,
  options: defaultOptions,
  search = false,
  border = 'none',
  height,
  fontSize,
  fontWeight,
  maxResultsInSearch,
  defaultOptionIfNoSearchResults,
  filterFunc,
  disablePointerEvents,
  ...rest
}: Props) => {
  const [options, setOptions] = useState(defaultOptions)

  return (
    <ThemeContext.Extend
      value={{
        select: {
          background: background,
          padding: '12px',
          border: 'none',
          container: {
            extend: () => css`
              max-height: 35vh;
              width: 100%;
              background-color: white;
              border: none;
              border-radius: 3px;
              pointer-events: ${disablePointerEvents ? 'none' : undefined};
            `,
          },
          options: {
            text: { style: { fontSize: `${fontSize}px`, fontWeight: fontWeight } },
            style: { borderRadius: '3px' },
            container: {
              height: height ? '100%' : undefined,
              justify: height ? 'center' : undefined,
            },
          },
          control: {
            open: { borderRadius: '3px' },
            extend: () => css`
              height: ${height}px;
              font-size: ${fontSize}px;
              font-weight: ${fontWeight};
              border: ${border};
              border-radius: 3px;
              background-color: white;
            `,
          },

          icons: {
            down: SelectDownIcon,
            up: SelectUpIcon,
          },
        },
      }}>
      <Select
        dropHeight="small"
        onClose={() => setOptions(defaultOptions)}
        options={options}
        onSearch={
          search && filterFunc
            ? (text) => {
                const escapedText = text.replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&')
                const exp = new RegExp(escapedText, 'i')
                let newOptions = filterFunc(defaultOptions, exp)
                if (maxResultsInSearch) {
                  newOptions = newOptions.slice(0, maxResultsInSearch)
                }
                if (!newOptions.length && defaultOptionIfNoSearchResults) {
                  setOptions([defaultOptionIfNoSearchResults])
                } else {
                  setOptions(newOptions)
                }
              }
            : undefined
        }
        {...rest}
      />
    </ThemeContext.Extend>
  )
}
