import { Box, Button, Heading, Layer, ResponsiveContext, Text } from 'grommet'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { MarketplaceCategory, UserMarketplaceCategoryOptions } from 'lib/constants'

import { CloseRateDialogIcon } from 'components/icons/common/CloseRateDialogIcon'
import { MarketplaceFilterIcon } from 'components/icons/common/MarketplaceFilterIcon'
import { colors } from 'layout/themeColors/colors'

import { MarketplaceFiltersContent } from './MarketplaceFiltersContent'

interface MarketplacePlaceFiltersProps {
  sortedMarketplaceCategories: MarketplaceCategory[]
  selectedCategoryOptions: UserMarketplaceCategoryOptions | undefined
  setSelectedCategoryOptions: React.Dispatch<
    React.SetStateAction<UserMarketplaceCategoryOptions | undefined>
  >
  loading?: boolean
  useMaxSelectedPerCategory?: boolean
  showLabels?: boolean
  translations:
    | {
        [key: string]: string
      }
    | undefined
}

export function MarketplacePlaceFilters({
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  loading,
  useMaxSelectedPerCategory = false,
  showLabels = false,
  translations,
  sortedMarketplaceCategories,
}: MarketplacePlaceFiltersProps) {
  const { t } = useTranslation()
  const deviceSize = React.useContext(ResponsiveContext)
  const isMobile = deviceSize === 'small'

  const [showMobileFilters, setShowMobileFilters] = useState(false)

  if (isMobile && !showLabels) {
    return (
      <Box gap="28px" direction="column">
        <Button
          onClick={() => setShowMobileFilters(true)}
          size="medium"
          label={
            <Box direction="row" gap="small" align="center">
              <Text>{t('marketplace.filters')}</Text>
              <MarketplaceFilterIcon />
            </Box>
          }
          primary
          style={{
            borderRadius: '16px',
            paddingLeft: '16px',
            paddingRight: '16px',
            width: 'fit-content',
          }}
        />
        <Box>
          {showMobileFilters && (
            <>
              {' '}
              <Layer
                background={colors.accent2}
                style={{ opacity: 0.3 }}
                onClick={() => setShowMobileFilters(false)}
              />
              <Layer
                style={{
                  width: '100%',
                  height: 'fit-content',
                  position: 'absolute',
                  borderTopLeftRadius: '16px',
                  borderTopRightRadius: '16px',
                }}
                position="bottom">
                <Box pad="40px" gap="40px">
                  <Heading size="24px">{t('marketplace.filter-by')}</Heading>
                  <MarketplaceFiltersContent
                    sortedMarketplaceCategories={sortedMarketplaceCategories}
                    selectedCategoryOptions={selectedCategoryOptions}
                    setSelectedCategoryOptions={setSelectedCategoryOptions}
                    loading={loading}
                    useMaxSelectedPerCategory={useMaxSelectedPerCategory}
                    showLabels={showLabels}
                    translations={translations}
                  />
                </Box>

                <Button
                  plain
                  icon={<CloseRateDialogIcon width={10} height={10} />}
                  onClick={() => setShowMobileFilters(false)}
                  style={{
                    position: 'absolute',
                    top: '24px',
                    right: '24px',
                  }}
                />
              </Layer>
            </>
          )}
        </Box>
      </Box>
    )
  }

  return (
    <MarketplaceFiltersContent
      sortedMarketplaceCategories={sortedMarketplaceCategories}
      selectedCategoryOptions={selectedCategoryOptions}
      setSelectedCategoryOptions={setSelectedCategoryOptions}
      useMaxSelectedPerCategory={useMaxSelectedPerCategory}
      showLabels={showLabels}
      loading={loading}
      translations={translations}
    />
  )
}
