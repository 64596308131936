export function MarketplaceFilterIcon() {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.25 17.1875C23.25 17.4859 23.1512 17.772 22.9754 17.983C22.7996 18.194 22.5611 18.3125 22.3125 18.3125H19.8281C19.6241 19.1215 19.2086 19.8302 18.6431 20.3339C18.0775 20.8377 17.3918 21.1096 16.6875 21.1096C15.9832 21.1096 15.2975 20.8377 14.7319 20.3339C14.1664 19.8302 13.7509 19.1215 13.5469 18.3125H1.6875C1.43886 18.3125 1.2004 18.194 1.02459 17.983C0.848772 17.772 0.75 17.4859 0.75 17.1875C0.75 16.8891 0.848772 16.603 1.02459 16.392C1.2004 16.181 1.43886 16.0625 1.6875 16.0625H13.5469C13.7509 15.2535 14.1664 14.5448 14.7319 14.0411C15.2975 13.5373 15.9832 13.2654 16.6875 13.2654C17.3918 13.2654 18.0775 13.5373 18.6431 14.0411C19.2086 14.5448 19.6241 15.2535 19.8281 16.0625H22.3125C22.5611 16.0625 22.7996 16.181 22.9754 16.392C23.1512 16.603 23.25 16.8891 23.25 17.1875ZM1.6875 5.9375H6.04687C6.25086 6.74648 6.66636 7.45522 7.23194 7.95895C7.79752 8.46267 8.48316 8.73464 9.1875 8.73464C9.89184 8.73464 10.5775 8.46267 11.1431 7.95895C11.7086 7.45522 12.1241 6.74648 12.3281 5.9375H22.3125C22.5611 5.9375 22.7996 5.81898 22.9754 5.608C23.1512 5.39702 23.25 5.11087 23.25 4.8125C23.25 4.51413 23.1512 4.22799 22.9754 4.01701C22.7996 3.80603 22.5611 3.6875 22.3125 3.6875H12.3281C12.1241 2.87852 11.7086 2.16978 11.1431 1.66606C10.5775 1.16233 9.89184 0.890366 9.1875 0.890366C8.48316 0.890366 7.79752 1.16233 7.23194 1.66606C6.66636 2.16978 6.25086 2.87852 6.04687 3.6875H1.6875C1.43886 3.6875 1.2004 3.80603 1.02459 4.01701C0.848772 4.22799 0.75 4.51413 0.75 4.8125C0.75 5.11087 0.848772 5.39702 1.02459 5.608C1.2004 5.81898 1.43886 5.9375 1.6875 5.9375Z"
        fill="white"
      />
    </svg>
  )
}
